.Profile{
    
}
.upperband_profile{
    display: flex;
    flex-direction: row;
    background-color: #b94d3d;
    width: 100%; 
    height: auto;
    justify-content: center;
    align-items: center;
    gap: 5%;
}

.flex_col{
    display: flex;
    flex-direction: column;
    align-self: center;
}
.flex_row{
    display: flex;
    flex-direction: row;
    align-self: center;

    
}

.dp_profile{
    height: 20vw;
    align-self: flex-start;
    width:20vw;
    border-radius: 50%;
    margin-left: 5%;
    margin-top: 4%;
    margin-bottom: 4%;
}
.field_subtitle_company_profile{
    font-size: 1.5vw;
    color: rgb(226, 217, 217);
}
.field_profile{
    color: black;
}

.field_expertise_profile{
    color: rgb(226, 217, 217);
    font-size: 1.8vw;
}
.field_subtitle_expertise_profile{
    align-self: center;
    font-size: 2.1vw;
    margin-left: 2%;
}
.field_subtitle_name_profile{
    align-self: center;
    font-size: 2.1vw;
    margin-left: 2%;
    color:white;
}
.field_subtitle_profile{
    width: auto;
    min-width: 40%;
    max-width: 40%;
    border-radius: 17px;
    padding: 1.4vw;
    align-self: center;
    font-size: 1.8vw;
    margin-right: 2%;
    color:black;
    background-color: rgba(255,255,255,0.65);
}

.values_profile{
    color:#187388;
}

.company_profile{
    font-size: 1.8vw;
}

  .buttonstyleUI_profile{
    display: inline-block;
    font-size: 1.6vw;
    padding:0.9vw;
    margin:0.5%;
    border-radius: 10px;
    background-color:#187388;
    cursor: pointer;
    ;
    /* color: black; */
    color:white
  }
  .buttonstyleUI_profile {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    background-image: linear-gradient(90deg, #2c92a8 0%, #2c92a8 50%, #187388 50%, #187388 100%);
    background-size: 200% 100%;
    background-position: right;
    transition: background-position 0.5s ease;
    color: #fff;
  }
  
  .buttonstyleUI_profile:hover {
    background-position: left;
  }

  .field_profile{
    font-size: 1.7vw;
    margin-bottom: 3.5vw;
  }
#about_margin{
    margin-left: 2vw;
}

.custom_button_profile{
    text-align: center;
}
.consultant_profile{
    padding-left: 10vw;
    padding-right: 10vw;
    margin-top: 5%;
    height: auto;
    display:flex;
    text-align: justify;
    justify-content: center;
    align-items: center;
}
.buttonstyleUI_profilebook{
    display: inline-block;
    font-size: 2.1vw;
    padding:0.9vw;
    padding-left: 8vw;
    padding-right: 8vw;
    margin:0.5%;
    border-radius: 10px;
    background-color:#b94d3d;
    cursor: pointer;
    ;
    /* color: black; */
    color:white
  }
  .buttonstyleUI_profilebook {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    background-image: linear-gradient(90deg, #d84843 0%, #d84843 50%, #b94d3d 50%, #b94d3d 100%);
    background-size: 200% 100%;
    background-position: right;
    transition: background-position 0.5s ease;
    color: #fff;
  }
  
  .buttonstyleUI_profilebook:hover {
    background-position: left;
  }
  /* Media query for smaller screens */
@media (max-width: 1000px) {
    .company_profile, .field_subtitle_profile, .field_expertise_profile{
      font-size: 2.3vw; /* Adjust the font size for smaller screens */
    }
    .field_subtitle_name_profile, .field_subtitle_expertise_profile{
        font-size: 2.5vw;
    }
    .field_subtitle_company_profile{
        font-size: 1.9vw;
    }
    .field_subtitle_profile{
        width: auto;
        min-width: 50%;
        max-width: 50%;
        padding: 1.4vw;
    }
    .field_profile{
        font-size: 2.3vw;
    }
    .dp_profile{
        height: 25vw;
        align-self: flex-start;
        width:25vw;
        border-radius: 50%;
        margin-left: 3%;
        margin-top: 3%;
        margin-bottom: 3%;
        gap: 3%;
    }
    .setmargin{
        margin-right: 3%;
    }
    .buttonstyleUI_profile{
        display: inline-block;
        font-size: 2.3vw;
        padding:1.3vw;
        margin:0.5%;
        border-radius: 1rem;
        background-color:#187388;
        ;
        /* color: black; */
        color:white
      }
      .buttonstyleUI_profilebook{
        display: inline-block;
        font-size: 2.3vw;
        padding:1.4vw;
        padding-left: 7vw;
        padding-right: 7vw;
        margin:0.5%;
        border-radius: 10px;
        background-color:#b94d3d;
        cursor: pointer;
        ;
        /* color: black; */
        color:white
      }
      .buttonstyleUI_profilebook {
        position: relative;
        cursor: pointer;
        overflow: hidden;
        background-image: linear-gradient(90deg, #d84843 0%, #d84843 50%, #b94d3d 50%, #b94d3d 100%);
        background-size: 200% 100%;
        background-position: right;
        transition: background-position 0.5s ease;
        color: #fff;
      }
      
      .buttonstyleUI_profilebook:hover {
        background-position: left;
      }
      
      .cost-box{
        min-width: 200px;
      }
  }
  
  /* Media query for even smaller screens */
  @media (max-width: 550px) {
    .upperband_profile{
        flex-wrap: wrap;
    }
    .company_profile, .field_subtitle_profile, .field_expertise_profile{
        font-size: 5vw; /* Adjust the font size for smaller screens */
      }
      .field_subtitle_name_profile, .field_subtitle_expertise_profile,.company_profile{
          font-size: 7vw;
      }
      .field_subtitle_company_profile{
          font-size: 4vw;
      }
      .field_subtitle_profile{
          width: auto;
          min-width: 50%;
          max-width: 50%;
      }
      .field_profile{
        font-size: 4.5vw;
        margin-bottom: 3.5vw;
        margin-top: 3.5vw;
      }
      .dp_profile{
          height: 50vw;
          align-self: flex-start;
          width:50vw;
          border-radius: 50%;
          margin-left: 3%;
          margin-top: 3%;
          margin-bottom: 3%;
          gap: 3%;
      }
      .setmargin{
          margin-right: 3%;
      }
      .buttonstyleUI_profile{
          display: inline-block;
          font-size: 5vw;
          padding:1.7vw;
          margin:0.5%;
          border-radius: 10px;
          background-color:#187388;
          margin-bottom: 2vw;
          ;
          /* color: black; */
          color:white
        }
        .buttonstyleUI_profilebook{
          display: inline-block;
          font-size: 7vw;
          padding:2vw;
          padding-left: 7vw;
          padding-right: 7vw;
          margin:0.5%;
          border-radius: 10px;
          background-color:#b94d3d;
          cursor: pointer;
          ;
          /* color: black; */
          color:white
        }
        .buttonstyleUI_profilebook {
          position: relative;
          cursor: pointer;
          overflow: hidden;
          background-image: linear-gradient(90deg, #d84843 0%, #d84843 50%, #b94d3d 50%, #b94d3d 100%);
          background-size: 200% 100%;
          background-position: right;
          transition: background-position 0.5s ease;
          color: #fff;
        }
        
        .buttonstyleUI_profilebook:hover {
          background-position: left;
        }
    
  }

  .company_profile{
    color: white;
  }

  .cost-box {
    border: 2px solid #b23c17; /* Matches your brand color */
    border-radius: 8px;
    border-width: 1.25px;
    padding: 10px;
    background-color: #f9f9f9; /* Subtle background for the box */
    max-width: 350px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .session-details p {
    color: #333;
  }

 
  
  .session-details strong {
    color: #b23c17; 
    font-size: 16px;/* Highlight key details in brand color */
  }
  
  .desc {
    color: rgba(0,0,0,0.85);
    font-weight:bold;
    font-size: 16px;
  }
  
  .costmargins{
    margin: 30px 13px;
  }
