@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

*, *:after, *:before {
	box-sizing: border-box;
}


input {
	appearance: none;
	border-radius: 0;
    padding: 0;
}

.popup-inner{
    overflow-x: hidden;
    padding: 0%;
}

.card {
	display: flex;
	flex-direction: column;
    margin-left: 2%;
    margin-right:2% ;
    padding: 0%;
	background-color: #b94d3d;
	border-radius: 20px;
}



.card-heading {
    color: white;
	/* position: absolute; */
	font-size: 1.75rem;
	font-weight: 700;
    padding-top: 2%;
    padding-left: 5%;
	line-height: 1.222;
    margin-bottom: 5%;
    margin-left: -3%;
    margin-top: -3%;
    margin-right: -3%;
}



.card-form {
	padding: 2rem 1rem 0;
}

/* .heading{
    background-color: #b94d3d;
    padding-top: 1%;
    padding-bottom: 1%;
    margin-top: -2.5%;
    margin-left: -2.5%;
    margin-right: -2.5%;
    margin-bottom: 1%;
} */


h3{
    color: white;
}

.input {
	display: flex;
	flex-direction: column-reverse;
	position: relative;
	padding-top: 1.5rem;
	&+.input {
		margin-top: 1.5rem;
	}
}

.input-label {
	color: #b94d3d;
	position: absolute;
    font-size:large ;
    font-weight: 600s;
	top: 1.5rem;
	transition: .25s ease;
}

.input-label-type {
	color: #b94d3d;
	position: absolute;
    font-size:large ;
    font-weight: 600s;
	top:0rem;
    margin-bottom: 1%;
	transition: .25s ease;
}



.input-field {
	border: 0;
	z-index: 1;
	background-color: transparent;
	border-bottom: 2px solid #ffffff; 
	font: inherit;
	font-size: 1.125rem;
	padding: .25rem 0;
	&:focus, &:valid {
		outline: 0;
		border-bottom-color: grey;
		&+.input-label {
			color: #b94d3d;
			transform: translateY(-1.5rem);
		}
	}
}


.container {
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: space-around; /* Center horizontally */
    background-color: #b94d3d;
    margin-bottom: 5%;
    scale: 1.11;
    margin-top: -3%;

  }

  .image1 {
    width: 150px; /* Adjust the width of the image as needed */
    height: auto; /* Maintain aspect ratio */
    margin-right: 20px; /* Adjust the spacing between image and text */
  }

  .para-text {
    flex: 1; /* Allow the text to take the remaining space */
  }

  .para-text{
    color: #ffffff;
    font-size:90%;
    margin-top: 7%;
    margin-right:-4%;
    margin-bottom: -5%;
  }

  select{
    margin-top: 3%;
    padding: 1.5%;
    background-color: #b94d3d;
    color: white;
    border-color: #b94d3d;
    height: auto;
    width: auto;
    font-size: large ;
    border-radius: 8px;
  }

.submit{
    color: #b94d3d;
}

.payment{
    display: inline-block;
    padding: 1.5%;
    background-color: #5EAA3C;
    border-radius: 8px;
    margin-left: 2%;
    margin-top: 5%;
}




.remove{
    margin-left: 2%;
}

.pay-link{
    text-decoration: none;
    color: #ffffff;
    font-size: large;
    font-weight: 550;
}

@media screen and (max-width:1550px) {


.payment{
    padding: 10%;
}
  }
  
  @media screen and (max-width:850px) {

.para-text{
    font-size: 75%;
}
.payment{
    padding: 2vw;
}
.cost-box{
  min-width: 200px;
}

  }


.bottombuttons{
    margin-top: 25px;

}

.topics{
    padding-bottom: 10px;
    font-weight: 600s;
    font-size:large;
    color: #b94d3d;
}

.centered-list {
    list-style: none; /* Remove default bullets */
    padding: 0;
    list-style-position: inside;
  }
  
  .centered-list li {
    display: flex;
    align-items: center; /* Vertically centers the list item */
    position: relative; /* Needed for custom bullet positioning */
    padding-left: 20px; /* Adjust to make space for custom bullet */
    padding-bottom: 10px;

  }
  
  .centered-list li::before {
    content: url("https://firebasestorage.googleapis.com/v0/b/consultationdojo.appspot.com/o/listdecorationsmall.png?alt=media&token=a84565c4-2f6d-4d18-a8bd-073b231ef6f6"); /* Custom bullet character */
    position: absolute;
    left: 0; /* Position it to the left of the text */
    top: 50%;
    transform: translateY(-50%); /* Center vertically */
    font-size: 1.5em; /* Adjust size if needed */
    color: black; /* Adjust color if needed */
  }

  .button-group {
    display: flex;
    gap: 10px;
  }
  
  .option-button {
    padding: 8px 16px;
    border: 1px solid #ccc;
    background: #f9f9f9;
    cursor: pointer;
  }
  
  .option-button.active {
    background: #b94d3d;
    color: #fff;
    border-color: #b94d3d;
  }

  .cost-box {
    border: 2px solid #b23c17; /* Matches your brand color */
    border-radius: 8px;
    padding: 10px;
    background-color: #f9f9f9; /* Subtle background for the box */
    min-width: 350px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .session-details p {
    color: #333;
  }

 
  
  .session-details strong {
    color: #b23c17; 
    font-size: 16px;/* Highlight key details in brand color */
  }
  
  .desc {
    color: rgba(0,0,0,0.85);
    font-weight:bold;
    font-size: 16px;
  }
  
  .costmargins{
    margin: 30px 0;
  }
