#head{
    color: #b94d3d;
    margin: 2% 2% 0% 2%;
}

p{
    color: black;
    margin:2%;
}

