.Resources {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: #333; /* Background for the entire resources section */
}

.headings {
  text-align: center;
  color: #b94d3d; /* Main color for headings */
  margin-bottom: 20px;
}

/* Testimonials Section */
.testimonials {
  padding: 40px 20px;
  margin-bottom: 40px;
}

.custom-carousel-inner .testimonial {
  background-color: #fff;
  border: 1px solid #b94d3d;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for individual testimonials */
  text-align: center;
  transition: transform 0.2s;
}

.feedback {
  font-style: italic;
  margin-bottom: 10px;
}

.name {
  font-weight: bold;
  color: #b94d3d;
}

.role {
  color: #777;
}

/* Carousel Controls */
.custom-carousel-control-prev,
.custom-carousel-control-next {
  color: #b94d3d; /* Red theme color */
  font-size: 30px;
  text-decoration: none;
  opacity: 1;
}

.custom-carousel-control-prev:hover,
.custom-carousel-control-next:hover {
  color: #8b3e32; /* Darker red on hover */
}

.custom-carousel-control-prev::before,
.custom-carousel-control-next::before {
  display: none; /* Hide default icons */
}

.custom-carousel-control-prev::after {
  content: '<'; /* Custom 'previous' control */
  font-size: 24px;
  color: #b94d3d;
  font-weight: bold;
}

.custom-carousel-control-next::after {
  content: '>'; /* Custom 'next' control */
  font-size: 24px;
  color: #b94d3d;
  font-weight: bold;
}

/* Smooth Fade Transition for Carousel */
.custom-carousel-inner .custom-carousel-item {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.custom-carousel-inner .custom-carousel-item.active {
  opacity: 1;
}

/* Statistics Section */
.statistics {
  margin-bottom: 40px;
}

.stats-list {
  display: grid;
  grid-template-columns: repeat(4, minmax(150px, 1fr)); /* 4 columns for larger screens */
  gap: 20px;
  justify-content: center; /* Center alignment on larger screens */
}

.stat-item {
  background: linear-gradient(145deg, #ffffff, #f2f0f0); /* Soft gradient background */
  border-radius: 12px;
  padding: 30px;
  text-align: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Pronounced shadow */
  transition: transform 0.2s, box-shadow 0.2s;
}

.stat-item:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); /* Deepen shadow on hover */
}

.value {
  font-size: 2.5rem; /* Large font size for values */
  color: #b94d3d;
  margin-bottom: 10px;
}
.label {
  color: #555;
  font-weight: 500; /* Medium weight for labels */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .statistics {
    margin: 2%; /* Margin from all sides for mobile screens */
  }

  .stats-list {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for mobile screens */
    gap: 15px;
  }

  .stat-item {
    padding: 15px; /* Reduced padding on mobile */
  }

  .value {
    font-size: 2.2rem; /* Smaller font size for values on mobile */
    color: #b94d3d;
    margin-bottom: 10px;
  }
}
