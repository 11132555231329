#home_consultant{
    position: relative;
    cursor: pointer;
    padding: 0;
    overflow: hidden;
    background-image: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 50%, rgb(248, 248, 248) 50%, rgb(248, 248, 248) 100%);
    background-size: 200% 100%;
    background-position: right;
    transition: background-position 1s ease-in-out 0.2s;
    color: #fff;
    transition: all 0.7s;
}

.expand_text{
    display: none;
    color: black;
    text-align: center;
}
#home_consultant:hover {
    background-position: left;
    
  }

#home_consultant:hover .expand_text{
    display: block;
    height: 17%;
    
}

#home_consultant:hover .field_subtitle{
  color: #b94d3d;
}
#home_consultant:hover .category_footer{
  background-color: white;
  border-style: solid;
  border-color: #b94d3d;
  border-left: none;
  border-right: none;
  border-width: 1.5px;
}
#home_consultant:hover .inline-list{
  padding-bottom: 2%;
}
.inline-list { /* Remove the default list bullets */
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    text-align: center;
    padding: 0; /* Remove default list padding */
    list-style-image: url("../../public/listdecorationsmall.png");
  }

.inline-list li {
    text-align: left;
    padding-left: 0; /* Reset text alignment for list items */
    margin-left: 20%; 
    margin-right: 10%;/* Add some spacing to simulate list item markers */
    position: relative; /* Create space for custom markers */
  }


  .slide-down-text {
    opacity: 0; /* Initially hide the text */
    animation: slide-down 0.35s forwards; /* Apply the slide-down animation */
  }
  .slide-down-text-1 {
    opacity: 0; /* Initially hide the text */
    animation: slide-down 0.5s forwards; /* Apply the slide-down animation */
  }
  .slide-down-text-2 {
    opacity: 0; /* Initially hide the text */
    animation: slide-down 0.65s forwards; /* Apply the slide-down animation */
  }
  .slide-down-text-3 {
    opacity: 0; /* Initially hide the text */
    animation: slide-down 0.8s forwards; /* Apply the slide-down animation */
  }
  .slide-down-text-4 {
    opacity: 0; /* Initially hide the text */
    animation: slide-down 0.95s forwards; /* Apply the slide-down animation */
  }
  
  @keyframes slide-down {
    0% {
      opacity: 0;
      transform: translateY(-100%); /* Start off the viewport */
    }
    60% {
        opacity: 0;
        transform: translateY(-50%); /* Move to original position */
      }
    65% {
        opacity: 0.4;
        transform: translateY(-40%); /* Move to original position */
      }
    70% {
        opacity: 0.35;
        transform: translateY(-35%); /* Move to original position */
      }
    75% {
        opacity: 0.35;
        transform: translateY(-35%); /* Move to original position */
      }
    80% {
        opacity: 0.3;
        transform: translateY(-30%); /* Move to original position */
      }
    100% {
      opacity: 1;
      transform: translateY(0); /* Move to original position */
    }
  }
  
  .text-container:hover .slide-down-text {
    animation-delay: 0s; /* Delay each text element's appearance */
  }



  .popup-inner {
    background-color: white;
    border-style: solid;
    border-color:#b94d3d;
    color: black;
    padding: 1rem;
    border-radius: 0.5rem;
    width: 100%;
    max-width: 700px;
    overflow: auto;
    max-height: calc(100vh - 4rem);
  }

  #group1{
    padding-top: 20px;
  }

  @media screen and (min-width:1300px) {

    li::marker{
      font-size: 15px;
      line-height: 3;
    }
  
    .custom_list{
      margin-bottom: 1.5%;
      font-size: 27px;
    }
  }


  #head{
    color: #b94d3d;

  }

  #steps_list{
    color: #b94d3d;
  }

  .slide-down-text{
    font-weight: 600;
    padding-left: 10px;
    padding-right: 10px;
  }

  

  /* Jumbotron styling */
.jumbotron-container-custom {
  position: relative;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/consultationdojo.appspot.com/o/breezing_up_(a_fair_wind)_1943.13.1%20(1).jpg?alt=media&token=68c62946-720c-42c8-92e4-7fe6a4807f2b'); /* Replace with actual background image */
  background-size: cover;
  background-position: center;
  padding: 7vh 20px; /* Extra padding for navbar */
  text-align: center;
  color: white;
  border-radius: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 77.5vh;
  max-height: 77.5vh;
}

/* Navbar styling */
.navbar-custom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 10px 15px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: transparent; /* Transparent background */
  color: white;
}

.navbar-brand-custom {
  color: #d64a1f; /* Brighter version of #b23c17 */
  font-weight: bold;
  font-size: 32px; /* Adjust as needed */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6); /* Adds subtle depth */
}

.jumbotron-brand {
  display: flex;
  align-items: center;
}

.brand-logo {
  width: 35px; /* Adjust size as needed */
  height: auto;
  margin-right: 5px; /* Space between logo and brand name */
}

.navbar-links-custom {
  list-style: none;
  display: flex;
  gap: 20px;
}

.navbar-links-custom a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
}

.navbar-links-custom a:hover {
  color: #ffcccb; /* Lighter color for hover effect */
}

/* Text content in the jumbotron */
.jumbotron-content-custom {
  margin-top: 60px; /* Space below the navbar */
}

.jumbotron-content-custom h1 {
  font-size: 26px;
  font-weight: bold;
  max-width: 800px;
  margin: 0 auto 20px auto;
  line-height: 1.4;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
}

/* Jumbotron button styling */
.jumbotron-button-custom {
  background-color: #ffffff;
  color: #d64a1f;/* Adjust as needed */
  border: none;
  padding: 12px 25px;
  font-size: 17px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.jumbotron-button-custom:hover {
  background-color: #cccccc;
}

/* Feature section styling */
.features-section-custom {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  padding: 20px 20px;
  border-radius: 15px;
  margin-top: 20px;
  flex-wrap: wrap;
  max-height: 30vh;
}

.feature-custom {
  max-width: 300px;
  text-align: center;
  margin: 20px;
}

.feature-custom h3 {
  font-size: 22.4px;
  margin-bottom: 10px;
  font-weight: 600;
}

.feature-custom p {
  font-size: 16px;
  color: #dddddd;
}



/* Responsive adjustments */

@media (min-height: 700px) {
  .jumbotron-container-custom {
    padding: 10vh 20px; /* Extra padding for navbar */
  }
}

@media (max-width: 768px) {

  .jumbotron-container-custom {
    padding: 5px 20px; /* Extra padding for navbar */
    min-height: 67vh;
    max-height: 67vh;
  }

  .jumbotron-content-custom h1 {
    font-size: 5.5vw;
  }

  .jumbotron-content-custom {
    margin-bottom: 4vw;
  }

  .jumbotron-button-custom {
    font-size: 4vw;
    padding: 10px 15px;
  }


  .features-section-custom {
    flex-direction: column;
    align-items: center;
  }

  .feature-custom {
    max-width: 75%;
    margin: 2.5px;
  }

  .features-section-custom {
    padding: 20px 20px;
    border-radius: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  .jumbotron-content-custom h1 {
    line-height: 1.7;
  }
  
  .navbar-links-custom {
    flex-direction: column;
    gap: 10px;
  }
  .hide-text {
    display: none;
  } 
  #jumbo-headers {
    font-size: 4vw;
    font-weight: bold;
  } 
  .inline-list li {
    font-size: 14px;
  }
  .slide-down-text{
    font-size: 16px;
    margin-left: 5%;
    margin-right: 5%;
  }
}

.hamburger-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

/* Default navbar links */
.navbar-links-custom {
  list-style: none;
  display: flex;
  gap: 24px;
  margin: 0;
  padding: 0;
}

/* Mobile menu adjustments */
@media (max-width: 768px) {
  .navbar-links-custom {
      display: none; /* Hidden by default */
      flex-direction: column;
      position: absolute;
      top: 60px;  /* Adjust top value to match navbar height */
      right: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.8);
      padding: 1rem 0;
  }

  /* Show menu when `show` class is added */
  .navbar-links-custom.show {
      display: flex;
  }

  /* Show hamburger icon on small screens */
  .hamburger-icon {
      display: block;
  }
}

/* Optional: Styling for individual menu items */
.navbar-links-custom li {
  text-align: center;
}

.navbar-links-custom li a {
  text-decoration: none;
  color: #ebe8e8;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  font-weight: 700;
  display: block;
}

.carousel-container {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%; /* Ensure it takes up the full screen width */
  max-width: 100%;
}

.carousel {
  display: flex;
  color: #b23c17;
  align-items: center;
  justify-content: center;
  padding: 32px;
  background-color: rgba(0, 0, 0, 0.85);
  width: 100%; /* Carousel takes up 100% of the container width */
  max-width: 100%;
  min-height: 22.5vh;
  max-height: 22.5vh;
  position: relative;
}

.carousel-slide {
  text-align: center;
}

.carousel h3 {
  font-size: 19.2px;
  font-weight: 700;
  margin-bottom: 12px;
}

.carousel p {
  font-size: 16px;
  margin-bottom: 16px;
  color: white;
}

.carousel .rating {
  font-size: 16px;
  font-weight: bold;
  color: #ff9900;
}

@media (min-height: 616px) and (max-height: 1200px) {
  /* Styles here will only apply when the screen width is between 600px and 1200px */
  .your-class {
    /* CSS properties for this range */
    background-color: lightblue;
    font-size: 1.2rem;
  }
}
/* Optional styling for responsiveness */
@media (max-width: 768px) {
  .carousel {
    flex-direction: column;
    max-width: 100%;
    padding: 1rem;
    min-height: 20vh;
    max-height: 20vh;
  }

  .jumbotron-container-custom {
    padding: 21vw 20px; /* Extra padding for navbar */
    min-height: 72vh;
    max-height: 72vh;
  }

  
  .carousel p {
    font-size: 4vw;
    margin-bottom: 0.5rem;
    color: white;
  }
}

.rating {
  font-size: 24px; /* Size of the stars */
  color: #ff9900; /* Gold color for the stars */
}

.rating i {
  margin-right: 5px; /* Space between stars */
}

.field_subtitle{
  color: white;
}

.category_footer{
  background: #b94d3d;
  padding-top: 1.5%;
  padding-bottom: 1.5%;
}

.workflow_header{
  background: #b94d3d;
  padding-top:1%;
  padding-bottom:0.30%;
  margin-left: 3%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-right: 3%;
}
.head_workflow{
  color: white;
}