
.groups {
  display: grid;
  max-width: 85%;
  margin: 2rem auto 0;
  margin-bottom: 3%;
  grid-template-columns: repeat(auto-fill, minmax(25vw, 1fr));
  grid-gap: 20px;
  
}

.group {
  background-color: #FDFFFC;
  width:80%;
  color: black;
  padding: 1rem;
  padding-bottom: 2rem;
  border-radius: 20px;
  text-align: left;
  border-style: solid;
  border-color:#2f2f96;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);
  position: relative;

}
#book_button_group{
  position: absolute;
  width:80%;
  bottom: 1.2%;
  left: 8%;
}

button {
  appearance: none;
  background: none;
  border-style: solid;
  /* border-color:#2f2f96; */
  outline: none;
  cursor: pointer;

  color: black;
  font-weight: bold;
  text-transform: uppercase;

  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  background-color: white;
}

.buttons_group {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-left: -0.5rem;
}

.custom-button_group {
  background-color: #2f2f96; /* Replace this with your desired color */
  color: #fff; /* Optionally, set the text color to make it readable */
  padding: 10px 20px; /* Optional: Add padding to the button for better appearance */
  border: none; /* Optional: Remove the default button border */
  border-radius: 5px; /* Optional: Add rounded corners for a nicer look */
} 
.custom-button_group {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  background-image: linear-gradient(90deg, #1e1eaf 0%, #1e1eaf 50%, #0e0e8d 50%, #0e0e8d 100%);
  background-size: 200% 100%;
  background-position: right;
  transition: background-position 0.5s ease;
  color: #fff;
}

.custom-button_group:hover {
  background-position: left;
}

.buttons button {
  margin: 0 0.5rem;
}



.popup-inner {
  background-color: white;
  border-style: solid;
  border-color:#b94d3d;
  color: black;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 700px;
  overflow: auto;
  max-height: calc(100vh - 4rem);
}


.buttonstyleUI{
  display: inline-block;
  padding:1.5%;
  margin:0.5%;
  border-radius: 8px;
  background-color:#187388;
  ;
  /* color: black; */
  color:white
}

.buttonstyleUI {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  background-image: linear-gradient(90deg, #2c92a8 0%, #2c92a8 50%, #187388 50%, #187388 100%);
  background-size: 200% 100%;
  background-position: right;
  transition: background-position 0.5s ease;
  color: #fff;
}

.buttonstyleUI:hover {
  background-position: left;
}



.group {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  background-image: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 50%, rgb(248, 248, 248) 50%, rgb(248, 248, 248) 100%);
  background-size: 200% 100%;
  background-position: right;
  transition: background-position 0.5s ease;
  color: black;
}

.group:hover {
  background-position: left;
}

.Group{
  overflow: auto;
}
.info {
  background-image: url('../../public/groupgiphy.png');
  background-size: cover; /* Adjust to your preference (cover, contain, etc.) */
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: 2px solid #2f2f96;
  color: rgb(255, 255, 255);
  font-size: 1.2rem;
  padding: 2.5rem;
  margin: 5%;
  border-radius: 20px;

 
}
#jumbo_h2{
  margin-bottom: 2%;
}
#jumbo_list{
  text-align: center;
  list-style: none;
}
.jumbo_list_element{
  margin-bottom: 3%;
}
.img{
    display: block;
    margin: 2rem auto;
    width: 80%;
    height: auto;
    border-radius: 40px;
    object-fit: cover;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.topic{
  color: #2f2f96;
  text-align: center;
  font-size: 1.45rem;
  font-family: sans-serif;
  margin-bottom: 0%;
}

.capacity{
  color: rgb(29, 29, 29);
  text-align: center;
  font-size: 1.25rem;
  font-family: sans-serif;
  margin-bottom: 3%;
}

.timing{
  color: rgb(29, 29, 29);
  text-align: center;
  font-size: 1.25rem;
  font-family: sans-serif;
  font-weight: 520;
  margin-bottom: 3%;
}

.time{
  color: #2f2f96;
}

.points{
  color: rgb(29, 29, 29);
  text-align: center;
  font-size: 1.25rem;
  font-family: sans-serif;
  font-weight: 520;
  margin-bottom: 3%;
}

.subtopics{
  color:#2f2f96 ;
  text-align: center;
  font-size: 1.2rem;
  font-family: sans-serif;
  margin-bottom: 1.5%;
}

/* .popup-inner {
  background-color: white;
  border-style: solid;
  border-color:#2f2f96;
  color: black;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 700px;
  overflow: auto;
  max-height: calc(100vh - 4rem);
} */
.note{
  text-align: center;
}
#form_topic{
  color: #2f2f96;
}

/* .close_button{
  background-color: #2f2f96;
  color: var(--light);
} */

.margin_top{
  margin-top: 1%;
}


@media screen and (max-width:1500px) {

  .groups {
    display: grid;
    max-width: 80%;
    margin: 1rem auto 0;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-gap: 1rem;
    column-gap: 2%;
  }

}

@media screen and (max-width:950px) {

  .groups {
    display: grid;
    width:100%;
    margin-bottom: 5%;
    grid-template-columns: repeat(auto-fill, minmax(1fr, 1fr));
  }
  .subtopics{
    font-size:1.1rem ;
  }
  .info{
    border-width: 0.2rem;
    width:90%;
    margin: 5%;
  }
  #group{
    width: 100%;
    border-style: solid;
    border-color:#2f2f96;
    border-width: 0.2rem;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
.tohide_phone{
  display: none;
}
.jumbo_list_element{
  margin-bottom: 10%;
}
#jumbo_list{
  list-style-type:disc;
}
.margin_top{
  margin-top: 5%;
}
}

