:root {
  --light: #eceaea;
  --dark: #342F4E;
  --darker: #262336;
  --blue: #61DAFB;
  --crimson: #b94d3d;
}

html {
  font-size: 16px; /* or your specific base font size */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Fira sans', sans-serif;
}

body {
  background-color: #AE9780;
  color: black;
}

.bar {
 padding: 1rem 0;
 margin-bottom: 2rem;
}

nav .navbar-nav li a{
  font-weight: 600 !important;
  display: inline;
  color: #2f2f31;
  
}
.title {
  color: #b94d3d;
  font-family: 'Rubik', sans-serif;
  font-size: 2rem;
  margin-left: 5rem;
  margin-right: 2rem;
}
.navtitle{
  color: #b94d3d;
  font-family: 'Rubik', sans-serif;
  font-size: 2rem;
}

.jumbo {
  background-image: url('../public/giphy.png');
  background-size: cover; /* Adjust to your preference (cover, contain, etc.) */
  background-repeat: no-repeat;
  text-align: left;
  /* border-style: solid;
  border-color:#b94d3d;
  border-width: thin; */
  margin: 2rem;
  padding: 3rem 2rem 1rem 2rem !important;
}
.center{
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.App {
  color: var(--light);
  text-align: center;
  padding-bottom: 2rem;
}

.field {
  font-weight: bolder;
}

h1 {
  font-size: 2.5rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

h1_nav{
  font-size: 2.5rem;
  text-transform: uppercase;
}

.consultants {
  display: grid;
  max-width: 85%;
  margin: 2rem auto 0;
  grid-template-columns: repeat(auto-fill, minmax(22.5vw, 1fr));
  grid-gap: 1rem;
  
}

.consultant {
  background-color: #FDFFFC;
  width:90%;
  margin: 0 auto;
  color: black;
  padding: 1rem;
  padding-bottom: 3rem;
  border-radius: 15px;
  text-align: left;
  border-style: solid;
  border-color:#b94d3d;
  border-width: 1.9px;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.5);
  position: relative;
}

#book_button{
  position: absolute;
  width:80%;
  bottom: 1.2%;
  left: 8%;
}

button {
  appearance: none;
  background: none;
  border-style: solid;
  border-color:#b94d3d;
  outline: none;
  cursor: pointer;

  color: black;
  font-weight: bold;
  text-transform: uppercase;

  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  background-color: white;
}

.buttons {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-left: -0.5rem;
}

.bottom_border{
  border-bottom: 2px solid #ebebeb;
  border-top: 2px solid #ebebeb;
  margin-bottom: 1%;
}

.custom-button {
  background-color: #b94d3d; /* Replace this with your desired color */
  color: #fff; /* Optionally, set the text color to make it readable */
  padding: 10px 20px; /* Optional: Add padding to the button for better appearance */
  border: none; /* Optional: Remove the default button border */
  border-radius: 5px; /* Optional: Add rounded corners for a nicer look */
} 
.custom-button {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  background-image: linear-gradient(90deg, #d84843 0%, #d84843 50%, #b94d3d 50%, #b94d3d 100%);
  background-size: 200% 100%;
  background-position: right;
  transition: background-position 0.5s ease;
  color: #fff;
}

.custom-button:hover {
  background-position: left;
}

.buttons button {
  margin: 0 0.5rem;
}

button.remove {
  color: var(--light);
  background-color: var(--crimson);
}

h4 {
  margin-bottom: 1rem;
  margin-top: 2rem;
}


li {
  line-height: 1.4;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  text-align: left;
}



.popup h2 {
  margin-bottom: 1rem;
}

.form-container {
  margin-bottom: 1rem;
}

.form-container:last-of-type {
  margin-bottom: 0;
}

input, textarea {
  display: block;
  width: 100%;
  padding: 0.5rem;
  resize: vertical;
  max-height: 200px;
  margin-bottom: 0.5rem;
}

.form-container label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-container button {
  margin-top: 1rem;
}

.alert {
  margin-left: 10rem;
  margin-right: 10rem;

}

.dp {
  display: block;
  margin: 2rem auto;
  width: 65%;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.subtitle {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;

}

.current {
  color:#b94d3d;
  font-size: 1rem;
  text-align: center;
}

.expertise {
  font-style: italic;
  color: #0d2b46;
}

a {

  color: black;
}

#about {
  color: black;
  text-align: left;
  margin: 1.5rem;
  padding: 3rem 0;
}

.workflow_image{
  background-color: white;
  padding-left: 5%;
  padding-right: 5%;
  border: 2px solid #b94d3d;
}

h3 {
  color: #b94d3d;
  font-family: 'Rubik', sans-serif;
  text-align: center;
  
}

#works {
  display: grid;
  height: 100%;
  margin: 4rem auto;
}

.center-fit {
  max-width: 100%;
  max-height: 80vh;
  margin: auto;
}




.footer {
  display: flex;
  flex-flow: row wrap;
  padding: 30px 30px 20px 30px;
  color: #2f2f2f;
  background-color: #fff;
  border-top: 1px solid #e5e5e5;
  margin-bottom: -50px;
}

.footer > * {
  flex:  1 100%;
}

.footer__addr {
  margin-right: 1.25em;
  margin-bottom: 2em;
}

.footer__logo {
  font-weight: 400;
  text-transform: capitalize;
  font-size: 2rem;
  color: #b94d3d;
}

.footer__addr h2 {
  margin-top: 1.3em;
  font-size: 15px;
  font-weight: 400;
}

address{
  align-items: center;
}

.nav__title {
  font-weight: 400;
  font-size: 15px;
}

.footer address {
  font-style: normal;
  color: #999;
}

.footer__btn {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  height: 36px;
  max-width: max-content;
  background-color: rgb(33, 33, 33, 0.07);
  border-radius: 100px;
  color: #2f2f2f;
  line-height: 0;
  margin: 0.6em 0;
  font-size: 1rem;
  padding: 0 1.3em;
}

.footer ul {
  list-style: none;
  padding-left: 0;
}

.footer li {
  line-height: 2em;
}

.footer a {
  text-decoration: none;
}

.footer__nav {
  display: flex;
	flex-flow: row wrap;
}

.footer__nav > * {
  flex: 1 50%;
  margin-right: 1.25em;
}

.nav__ul a {
  color: #999;
}

.nav__ul--extra {
  column-count: 2;
  column-gap: 1.25em;
}

.legal {
  display: flex;
  flex-wrap: wrap;
  color: #999;
  justify-content: space-between;
}

#legal_slogan{
  
}

#logocolor{
  color:#b94d3d
}


#workflow_button{
  justify-content: center;
  align-items: center;
  width: 100%;
}



.about_lines{
  padding-left:10%;
  padding-right: 10%;
  text-align: center;
  
}

#small_heading{
  padding-left: 15%;
  color:#b94d3d;
}

#about_more{
  padding-left: 15%;
  padding-right: 15%;
  text-align:justify ;
}

#about_last{
  padding-left: 15%;
  padding-right: 15%;
  text-align:justify ;
}

.align {
  margin: auto;
  width: 70%;
  padding: 10px;
}

.tag {
  display: inline-block;
  margin: 0.1rem;
  padding: 0.4rem;
  background-color: #10375a;
  color: white;
  border-radius: 20px;
  font-size: 0.9rem;
}

.catimg {
  margin-top: 1rem;
  margin-bottom: 1rem;
}


.docs{
  color: #2f2f2f;
  background-color: whitesmoke;
}

.buttonstyleUI{
  display: inline-block;
  padding:1.5%;
  margin:0.5%;
  border-radius: 8px;
  background-color:#187388;
  /* color: black; */
  color:white
}

.buttonstyleUI {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  background-image: linear-gradient(90deg, #2c92a8 0%, #2c92a8 50%, #187388 50%, #187388 100%);
  background-size: 200% 100%;
  background-position: right;
  transition: background-position 0.5s ease;
  color: #fff;
}

.buttonstyleUI:hover {
  background-position: left;
}

.consultant {
  transition: transform 0.3s; /* Add a smooth transition effect */
  overflow: hidden;
  cursor: pointer;
  margin-top: 5%;
  margin-bottom: 5%;
}

.consultant:hover {
  transform: scale(1.1); /* Enlarge the card on hover */
}

.field_subtitle{
  margin-top: 5% ;
  font-weight: 600;
  text-align: center;
  font-size: 1.4rem;
  letter-spacing: 0.06rem;
  word-spacing: 0.3rem;
  padding: 0%;
  margin:2%;
  font-family: Arial, Helvetica, sans-serif;
  color: #191616;
}

.field_expertise{
  font-weight: bolder;
  font-style: italic;
  color: #0d2b46;
  text-align: center;
}

.consultant {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  background-image: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 50%, rgb(248, 248, 248) 50%, rgb(248, 248, 248) 100%);
  background-size: 200% 100%;
  background-position: right;
  transition: background-position 0.5s ease;
  color: #fff;
}

.consultant:hover {
  background-position: left;
}

.jumbo_buttons{
  display: flex;
  flex-direction: row;
}

.custom_button_jumbo{
  text-align: center;
  margin-right: 1.3%;
}

.buttonstyleUI_jumbo{
  display: inline-block;
  
  font-size: 0.8rem;
  width: 30vw;
  max-width: 150px;
  min-width: 100px;
  padding:0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin:0.5%;
  border-radius: 10px;
  background-color:#b94d3d;
  cursor: pointer;
  ;
  /* color: black; */
  color:white
}
.buttonstyleUI_jumbo {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  background-image: linear-gradient(90deg, #d84843 0%, #d84843 50%, #b94d3d 50%, #b94d3d 100%);
  background-size: 200% 100%;
  background-position: right;
  transition: background-position 0.5s ease;
  color: #fff;
}

.buttonstyleUI_jumbo:hover {
  background-position: left;
}

.custom_list { /* Change the font family as needed */
  font-size: 21px; /* Change the font size as needed */ /* Change the font weight as needed */
  /* Add more font-related styles if desired */
  list-style-image: url("../public/listdecoration.png");
    list-style-type: none; /* Remove default list bullets */
    padding: 0; /* Remove default padding */
}

.jumbo li {
  text-align: center; /* Center text horizontally */
  line-height: 1; /* Adjust line-height to center text vertically */
}

.workflow_image{
  display: flex;
  margin-left: 3%;
  margin-right: 3%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  /* border-radius: 20px; */
}
.shrink{
  height: auto;
  width:50%

}

@media screen and (max-width:1550px) {

  .consultants {
    display: grid;
    max-width: 80%;
    margin: 1rem auto 0;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 1rem;
    column-gap: 2%;
  }
  .field_subtitle{
    font-size: 1.3rem;
  }

}

@media screen and (max-width:850px) {

  .consultants {
    display: grid;
    width:100%;
    grid-template-columns: repeat(auto-fill, minmax(1fr, 1fr));
  }
  nav .navbar-nav li a{
    font-weight: 600 !important;
    display: block;
  }
  #consultant{
    width: 100%;
  }
  .consultant{
    border-width: 1.2px;
  }
  #learnmore{
    font-size: 2vw;
    white-space: nowrap;
  }
  .tohide_phone{
    display: none;
  }
  #nav_title{
    margin-left: 4vw;
  }
  .custom_list{
    font-size: 17px;
    width: 85%;
    list-style-image: url("../public/listdecorationsmall.png");

  }

  li::marker{
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 1%;
  }
  .shrink{
    height: auto;
    width:50%
  }
  .workflow_image{
    flex-direction: column;
  }
}



.navbar{
  padding: 0.5%;
  margin-bottom: 0;
}

.marginbottom{
  margin-bottom: 5%;
}

#learnmore{
  margin-top: 1.5%;
  font-size: 1rem;
    text-align: center; /* Center the text horizontally */
    vertical-align: middle; /* Center the text vertically */
    padding: 10px 20px; /* Add padding to the button for spacing */
    display: inline-block; /* Make the button behave like an inline element */
}

#works h3{
  font-size: 2rem;
}

#about h3{
  font-size: 2rem;
}

.bottom_space{
  margin-bottom: 1%;
}
.popup-inner {
  background-color: white;
  border-style: solid;
  border-color:#b94d3d;
  color: black;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 700px;
  overflow: auto;
  max-height: calc(100vh - 4rem);
}

#pay-button {
  visibility: hidden;
}

.button-group {
  display: flex;
  gap: 10px;
}

.option-button {
  padding: 8px 16px;
  border: 1px solid #ccc;
  background: #f9f9f9;
  cursor: pointer;
}

.option-button.active {
  background: #b94d3d;
  color: #fff;
  border-color: #b94d3d;
}
